<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-7">
        <div class="container banner-container-2">
            <div class="row">
                <div class="col-lg-7">
                    <div class="banner-img">
                        <img src="images/banner/7-1-837x1185.png" alt="Banner">
                    </div>
                </div>
                <div class="col-lg-5 align-self-center">
                    <div class="banner-content">
                        <span class="banner-category">Easy Collection System</span>
                        <h2 class="banner-title">Collect your plant for your home and office to relax spaces.</h2>
                        <p class="banner-desc">That necessitates a robust ecommerce platform that optimizes your store & products</p>
                        <div class="btn-wrap">
                            <router-link to="/contact" class="btn btn-custom-size blackcurrant-color">Download Now</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>

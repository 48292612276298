<template>
    <div class="banner-content">
        <span class="banner-category">Trial Version Available</span>
        <h2 class="banner-title">Start your 5-day’s free trial.</h2>
        <p class="banner-desc">That necessitates a robust ecommerce platform that optimizes
            remaining unchanged your store & products
        </p>
        <div class="banner-btn-wrap">
            <router-link to="/contact"><img src="images/button/5-1-213x73.png" alt="Button"></router-link>
            <router-link to="/contact"><img src="images/button/5-2-213x73.png" alt="Button"></router-link>
        </div>
    </div>
</template>
